<template>
  <div class="py-md-8 py-6">
    <s-loading :padding="5" v-if="fetching" />
    <v-form v-else v-model="formIsValid" ref="floorColorForm" @submit.prevent="handleFormSubmit" lazy-validation>
      <v-row>
        <v-col>
          <label class="text-subtitle-2 gray--text text--darken-2 d-inline-block mb-3">
            {{ $t("createOrder.colors.form.floorColor.label") }}
          </label>
          <v-radio-group :rules="rules.floorColorId" class="floor-colors" hide-details="auto" row
                         v-model="form.floorColorId">
            <template v-for="floorColor in floorColors">
              <v-radio active-class="active" class="image-radio" :key="floorColor.id" :value="floorColor.id">
                <template v-slot:label>
                  <div class="my-2 d-flex flex-column align-center">
                    <img :src="getAttachmentPreviewURL(floorColor.image)" />
                    <span class="d-inline-block mt-1 text-subtitle-2">{{ floorColor.displayName }}</span>
                  </div>
                </template>
              </v-radio>
            </template>
          </v-radio-group>
        </v-col>
      </v-row>
      <div class="mt-md-8 mt-6 action-buttons">
        <v-btn
          type="button"
          elevation="0"
          class="mr-4 rounded-0"
          outlined
          @click="goToPreviousStep"
          large
        >
          {{ $t("defaults.back") }}
        </v-btn>
        <v-btn
          type="submit"
          elevation="0"
          color="primary"
          :disabled="!formIsFilled || !formIsValid"
          class="rounded-0"
          large
        >
          {{ $t("defaults.continue") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getAttachmentPreviewURL } from "@/utils/attachment";

export default {
  data() {
    return {
      formIsValid: false,
      fetching: false,
      form: {
        floorColorId: null
      },
      rules: {
        floorColorId: [
          v => !!v || this.$t("createOrder.colors.form.floorColor.rules.required")
        ]
      }
    };
  },
  computed: {
    ...mapState("Colors", {
      floorColors: state => state.floorColors
    }),
    formIsFilled() {
      return Boolean(this.form.floorColorId);
    }
  },
  methods: {
    getAttachmentPreviewURL,
    ...mapActions("Steps", ["completeStep"]),
    ...mapActions("Colors", ["getFloorColors", "saveSelectedFloorColor"]),
    handleFormSubmit() {
      const isValid = this.$refs.floorColorForm.validate();
      if (isValid) {
        this.saveSelectedFloorColor(this.form.floorColorId);
        this.completeStep("colors/floor");
        this.$router.push("/design-countertop/colors/cabinet");
      }
    },
    goToPreviousStep() {
      this.$router.push("/design-countertop/kitchen-layout/sqft");
    }
  },
  async mounted() {
    if (this.floorColors.length === 0) {
      this.fetching = true;
      await this.getFloorColors();
      this.fetching = false;
    }

    const { selectedFloorColor } = this.$store.state.Colors;
    if (selectedFloorColor) {
      this.form.floorColorId = selectedFloorColor.id;
    }
  }
};
</script>
